
























import { Component, Vue } from "vue-property-decorator"; // @ is an alias to /src
import Statistic from "@/components/StatisticDesc.vue";
import Description from "@/components/Description.vue";

@Component({
    components: {
        Statistic,
        Description
    },
    props: {
        country: {
            type: String
        }
    }
})
export default class Home extends Vue {}
