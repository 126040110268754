


















































































import { Component, Vue } from 'vue-property-decorator'
import Authenticate from './components/Authenticate.vue'

interface CountryConfig {
  country: string
  name: string
  flag: string
  domain: string
}

@Component({
  components: {
    Authenticate
  }
})
export default class App extends Vue {
  authenticated: boolean = false
  countries: CountryConfig[] = [
    {
      country: 'Taiwan',
      name: 'tw',
      flag: 'https://flagcdn.com/h120/tw.png',
      domain: 'https://url.rhinoshield.tw'
    },
    {
      country: 'US',
      name: 'io',
      flag: 'https://cdn.vuetifyjs.com/images/flags/us.png',
      domain: 'https://url.rhinoshield.io'
    },
    {
      country: 'France',
      name: 'fr',
      flag: 'https://flagcdn.com/h120/fr.png',
      domain: 'https://url.rhinoshield.fr'
    },
    {
      country: 'Germany',
      name: 'de',
      flag: 'https://flagcdn.com/h120/be.png',
      domain: 'https://url.rhinoshield.de'
    },
    {
      country: 'Japan',
      name: 'jp',
      flag: 'https://flagcdn.com/h120/jp.png',
      domain: 'https://url.rhinoshield.jp'
    },
    {
      country: 'Spain',
      name: 'es',
      flag: 'https://flagcdn.com/h120/es.png',
      domain: 'https://url.rhinoshield.es'
    },
    {
      country: 'Unite Kingdom',
      name: 'uk',
      flag: 'https://flagcdn.com/h120/gb.png',
      domain: 'https://url.rhinoshield.uk'
    },
    {
      country: 'Vietnam',
      name: 'vn',
      flag: 'https://flagcdn.com/h120/vn.png',
      domain: 'https://url.vn.rhinoshield.store'
    },
    {
      country: 'Thailand',
      name: 'th',
      flag: 'https://flagcdn.com/h120/th.png',
      domain: 'https://url.rhinoshield.co.th'
    },
    {
      country: 'Asia',
      name: 'asia',
      flag: 'https://flagcdn.com/h120/tw.png',
      domain: 'https://url.rhinoshield.asia'
    }
  ]

  visitHome(): void {
    this.$router.push('/')
  }

  visitStatistic(): void {
    this.$router.push('/statistic')
  }

  visitCountryDomain(country: any): void {
    window.location.href = country.domain
  }

  checkTheAuth(auth: boolean): void {
    this.authenticated = auth
    return
  }

  get selectedCountry(): string {
    const country = this.countries.filter((country: any) => country.domain === window.location.origin).length > 0 ?
    this.countries.filter((country: any) => country.domain === window.location.origin)[0]['name']
    : 'tw'
    return country
  }

  get flag(): string {
    const flag = this.countries.filter((country: any) => country.domain === window.location.origin).length > 0 ?
    this.countries.filter((country: any) => country.domain === window.location.origin)[0]['flag']
    : 'https://flagcdn.com/120x90/tw.png'
    return flag
  }
}
