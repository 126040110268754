


































import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'

@Component({})
export default class UrlBasicInfo extends Vue {
  dialog: boolean = false
  password: string = ''
  showPassword: boolean = false
  wrongPassword: boolean = false

  async checkThePassword() {
    const { data } = await axios({
          method: 'GET',
          url: '/api/password',
          params: {
            data: this.password
          }
    })
    if (data !== true) return
    window.localStorage.setItem('authPassword', this.password)
    this.$emit('checkTheAuth', true)
    this.dialog = false
    return
  }

  async mounted() {
    this.dialog = true
    const existPwd = window.localStorage.getItem('authPassword')
    const { data } = await axios({
      method: 'GET',
      url: '/api/password',
      params: {
        data: existPwd
      }
    })
    if (data !== true) return
    this.$emit('checkTheAuth', true)
    this.dialog = false
  }
}
